<template>
    <div class="page">
        <el-form class="query-form" :inline="true" size="small" ref="searchForm" :model="searchForm"
                 label-width="100px" @keyup.enter.native="submitForm()">
            <el-form-item prop="name" label="关键字查询：">
                <el-input clearable v-model.trim="searchForm.name"
                          maxlength="66" placeholder="请输入资源名称或编号"></el-input>
            </el-form-item>
            <el-form-item prop="type" label="资源类型：">
                <el-select v-model="searchForm.type"
                           placeholder="请选择资源类型" class="w100i">
                    <el-option
                            v-for="item in $dictUtils.getDictList('resource_type')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="createBy" label="编目人：">
                <el-select v-model="searchForm.createBy" filterable placeholder="请选择编目人" clearable class="w100i">
                    <el-option v-for="item in userList" :key="item.id" :label="item.name"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="data" label="编目时间：">
                <el-date-picker value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                v-model="searchForm.data"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm()" size="small"
                           icon="el-icon-search">查询
                </el-button>
                <el-button @click="resetForm()" size="small"
                           icon="el-icon-refresh-right">重置
                </el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white">
            <el-tabs v-model="searchForm.processingState" size="small" @tab-click="tabChange()">
                <el-tab-pane label="待处理" name="0"></el-tab-pane>
                <el-tab-pane label="已处理" name="1"></el-tab-pane>
            </el-tabs>
            <div class="flex_b_c">
                <AdvancedQuery ref="advancedQuery" :moduleId="moduleId" @getDataList="atlGetDataList">
                </AdvancedQuery>
                <div class="flex_r_c">
                    <el-button icon="el-icon-circle-check" type="primary" size="small"
                               v-if="hasPermission('resourceAuditList:pass') && resourceState == '2'"
                               @click="audit(null, 0)">批量通过
                    </el-button>
                    <el-button icon="el-icon-circle-close" type="danger" size="small"
                               v-if="hasPermission('resourceAuditList:reject') && resourceState == '2'"
                               @click="audit(null, 1)">批量驳回
                    </el-button>
<!--                    <el-button size="small" plain icon="el-icon-setting" @click="setTb"></el-button>-->
                </div>
            </div>
            <el-table
                    :data="appraisalDataList"
                    v-loading="appraisalLoading"
                    size="small"
                    ref="rightList"
                    height="calc(100vh - 433px)"
                    @selection-change="appSelectionChangeHandle"
                    class="table" row-key='id'>
                <el-table-column
                        type="selection"
                        width="55" :reserve-selection='true'>
                </el-table-column>
                <el-table-column v-for="(item,index) in columnList" :key="index"
                                 :prop="item.fieldEname" min-width="160"
                                 show-overflow-tooltip sortable
                                 :label="item.fieldName">
                    <template slot-scope="scope">
                                    <span v-if="item.fieldEname=='resource_type'">
                                        {{$dictUtils.getDictLabel("resource_type",scope.row.resource_type,'-')}}
                                    </span>
                        <span v-else-if="item.fieldEname=='resource_state'">
                                            {{$dictUtils.getDictLabel("resource_state",scope.row.resource_state,'-')}}
                                    </span>
                        <span v-else-if="item.fieldEname=='on_collection'">
                                            {{scope.row.on_collection ===  0 ? '否' : '是'}}
                                    </span>
                        <span v-else-if="item.fieldEname=='update_user'">
                                            {{scope.row.updateUserName}}
                                    </span>
                        <span v-else>{{scope.row[item.fieldEname]}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createUserName" label="采集人" min-width="160"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="采集时间" min-width="160"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="updateUserName" label="编目人" min-width="160"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="update_time" label="编目时间" min-width="160"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="resource_state" label="资源状态" min-width="160" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("resource_state", scope.row.resource_state ,'')}}
                    </template>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="180"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"
                                   v-show="hasPermission('admin:resources:view')"
                                   @click="see(scope.row)">详情
                        </el-button>
                        <el-button type="text" size="small"
                                   v-if="hasPermission('resourceAuditList:auditLog')"
                                   @click="auditLog(scope.row.id)">审核记录
                        </el-button>
                        <el-button type="text" size="small"
                                   v-show="hasPermission('resourceAuditList:pass') && resourceState == '2'"
                                   @click="audit(scope.row.id, 0)">通过
                        </el-button>
                        <el-button type="text" size="small"
                                   v-show="hasPermission('resourceAuditList:reject') && resourceState == '2'"
                                   @click="audit(scope.row.id, 1)">驳回
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="appSizeChangeHandle"
                    @current-change="appCurrentChangeHandle"
                    :current-page="appPageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="appPageSize"
                    :total="appTotal"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--列表设置拖拽排序弹窗-->
        <DraggablePop :moduleId="moduleId" :setShow="appraisalSetShow"
                      @getTbList="getTbList"></DraggablePop>
        <!--审核建议-->
        <ResourceAuditForm ref="resourceAuditForm" @refreshDataList="appGetLiat()"></ResourceAuditForm>
        <!--查看-->
        <ViewThreeForm ref="viewThreeForm"></ViewThreeForm>

        <!--查看-->
        <ViewForm ref="viewForm"></ViewForm>
        <AuditLog ref="auditLog"></AuditLog>
    </div>
</template>

<script>
    import AuditLog from './auditLog'
    import DraggablePop from '@/components/draggable/draggablePop2' // 导入排序弹窗
    import AdvancedQuery from '@/components/advancedQuery/advancedQuery' // 导入高级筛选弹窗
    import ViewThreeForm from '../gather/resource/three/viewThreeForm' // 三维查看弹窗
    import ViewForm from '../gather/resource/components/viewForm' // 查看弹窗
    import ResourceAuditForm from './resourceAuditForm'

    export default {
        name: "resourceIdentification",
        components: {ResourceAuditForm, AuditLog, DraggablePop, AdvancedQuery, ViewThreeForm, ViewForm},
        data() {
            return {
                searchForm: {
                    name: '',
                    type: '',
                    data: '',
                    createBy: '',
                    processingState: '0',
                },
                userList: [],
                columnList: [],
                appraisalDataList: [],
                appraisalLoading: false,
                appDataListSelections: [],//多选
                moduleId: '958321248549470208',   // 当前请求拖拽排序数据id
                appraisalSetShow: false,
                appPageNo: 0,
                appPageSize: 10,
                appTotal: 0,
                resourceState: '2',
                onScene: false, // 是否正在使用场景
                dataListQuery: [], // 临时储存场景数据
            }
        },
        mounted() {
            this.appGetLiat()
            this.getUserList()
        },
        methods: {
            tabChange() {
                let val = this.searchForm.processingState
                if (val == 0) {
                    this.resourceState = 2;
                } else if (val == 1) {
                    this.resourceState = '';
                }
                this.$refs.searchForm.resetFields();
                this.resetForm()
            },


            //查询
            submitForm() {
                this.appPageNo = 0;
                this.appGetLiat();
            },

            // 获取用户列表
            getUserList() {
                this.$axios(this.api.auth.sysuserQueryAllList, {
                    current: 1,
                    size: 9999,
                    orderBy: '',
                    name: '',
                    loginName: '',
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.userList = data.data.records
                    }
                })
            },

            //单个数据查看
            see(row) {
                if (row.resource_type == 0) { //三维
                    this.$refs.viewThreeForm.init(row);
                } else {
                    this.$refs.viewForm.init(row.id, row.resource_type, this.moduleId);
                }
            },
            //未鉴定资源
            //列表数据请求
            //type: -1 时为重置不需要考虑高级筛选
            appGetLiat(type) {
                if (this.searchForm.data == null) {
                    this.searchForm.data = []
                }
                if (type != -1) {
                    if (this.onScene || (this.dataListQuery.queryConditions != null && this.dataListQuery.queryConditions.length > 0)) {
                        this.getDataList(this.dataListQuery)
                        return
                    }
                }
                this.appraisalLoading = true;
                this.$axios(this.api.identification.list, {
                    current: this.appPageNo,
                    resourceNameOrId: this.searchForm.name,
                    resourceType: this.searchForm.type,
                    size: this.appPageSize,
                    startTime: this.searchForm.data[0],
                    endTime: this.searchForm.data[1],
                    resourceState: this.resourceState,
                    createBy: this.searchForm.createBy,
                    processingState: this.searchForm.processingState,
                }, 'get').then(res => {
                    if (res.status) {
                        this.appraisalLoading = false;
                        this.appraisalDataList = res.data.records;
                        this.appTotal = parseInt(res.data.total);
                        if (this.appraisalDataList.length == 0 && this.appPageNo > 1) {
                            this.appPageNo--
                            this.appGetLiat()
                        }
                    }
                })
            },

            // 多选
            appSelectionChangeHandle(val) {
                this.appDataListSelections = val;
            },

            // 每页数
            appSizeChangeHandle(val) {
                this.appPageSize = val
                this.appPageNo = 0
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    this.appGetLiat();
                }
            },

            // 当前页
            appCurrentChangeHandle(val) {
                this.appPageNo = val
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    this.appGetLiat();
                }
            },

            // 审核记录
            auditLog(id) {
                this.$refs.auditLog.init(id, 'resourceAuditList', this.resourceState,21)
            },

            // 审核  type：0通过，1驳回
            audit(id, type) {
                let ids = [id];
                if (!id && !this.appDataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                if (!id) {
                    ids = this.appDataListSelections.map(item => {
                        return item.id
                    })
                }
                this.$refs.resourceAuditForm.init(ids, type)
            },

            //重置
            resetForm() {
                this.appDataListSelections = []
                this.$refs.rightList.clearSelection()
                this.appPageNo = 0;
                this.$refs.searchForm.resetFields();
                this.dataListQuery = []
                this.$refs.advancedQuery.addForm.sceneId = ''
                this.$nextTick(() => {
                    this.dataListQuery.queryConditions = []
                    this.appGetLiat(-1);
                })
            },
            // 高级查询
            atlGetDataList(data) {
                this.appPageNo = 0
                this.getDataList(data)
            },
            // 获取高级查询条件
            getDataList(data) {
                if (data != null && data.sceneId != null && data.sceneId != '') {
                    this.onScene = true
                } else {
                    this.onScene = false
                }
                if (data != null && data.clearCurrent != null && data.clearCurrent == true) {
                    this.pageNo = 0
                }
                this.dataListQuery = data
                data.size = this.appPageSize
                data.current = this.appPageNo
                data.resourceState = this.resourceState
                data.searchNumOrName = this.searchForm.name
                data.type = this.searchForm.type
                data.startTime = this.searchForm.data[0]
                data.endTime = this.searchForm.data[1]

                this.$axios(this.api.rcPage.appraisalQueryResource, JSON.stringify(data), 'post').then(res => {
                    if (res && res.status) {
                        this.appraisalDataList = res.data.records;
                        this.appTotal = parseInt(res.data.total);
                    }
                })
            },

            // 设置
            setTb() {
                this.appraisalSetShow = true;
            },

            // 接收子组件（排序弹窗）传过来的值
            getTbList(data, state) {
                this.columnList = data;
                this.appraisalSetShow = state;
                this.$nextTick(() => {
                    this.$refs.rightList.doLayout()
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .searchForm {
        padding-top: 20px;

        .el-form-item--small.el-form-item {
            display: inline-block;
            width: 33.33%;
        }
    }
</style>
